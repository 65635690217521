<template>
  <v-card>
    <v-card-title>
      What Doricmor service are you interested in?
    </v-card-title>
    <v-card-text>
      <v-radio-group v-model="userType" @change="changeCustomerType">
        <v-radio value="CUSTOMER">
          <template v-slot:label>
            <v-col>
            <div>We are a retailer (gift shop, gallery, framer, visitor attraction etc) and we would like to sell Doricmor products</div>
              <v-checkbox
                v-if="userType === 'CUSTOMER'"
                v-model="isPublisher"
                @change="handleChangePublisher"
                label="We would also like to publish our own exclusive products"
              ></v-checkbox>
            </v-col>
          </template>
        </v-radio>
        <v-radio value="PUBLISHER">
          <template v-slot:label>
            <v-col>
            <div>We are an artist or organisation, and would like to use Doricmor to create and publish our own artwork and/or merchandise</div>
            </v-col>
          </template>
        </v-radio>
      </v-radio-group>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  props: {
    formData: Object,
    rules: Object
  },
  created: async function () {
    // this.formData.accessRoles = [this.userType]
  },
  data: () => ({
    userType: 'CUSTOMER',
    isPublisher: false
  }),
  computed: {
  },
  methods: {
    changeCustomerType (e) {
      if (e === 'CUSTOMER' && this.isPublisher) {
        this.formData.accessRoles = ['CUSTOMER', 'PUBLISHER']
      } else if (e === 'CUSTOMER') {
        this.formData.accessRoles = ['CUSTOMER']
      } else {
        this.formData.accessRoles = ['PUBLISHER']
      }
    },
    handleChangePublisher (e) {
      if (e) {
        this.formData.accessRoles = ['CUSTOMER', 'PUBLISHER']
      } else {
        this.formData.accessRoles = ['CUSTOMER']
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .v-input--selection-controls__input {
  align-self: flex-start;
  margin-top: 12px;
}
</style>
